import { IPayInfo, PaymentProviderTypesType } from '@/shared/payment/types';

abstract class PayInfoBuilder {
  protected payInfo!: IPayInfo;

  protected constructor() {
    this.init()
  }
  init(): this {
    this.payInfo = {
      payLink: '',
      paymentProvide: 'paddle',
      getPaymentLink() {
        if (this.paymentProvide === 'paddle') {
          const startIndex = this.payLink.lastIndexOf('/')
          return this.payLink.slice(startIndex)
        }
        return this.payLink
      }
    }
    return this
  }

  setPayLink(link: string): this {
    this.payInfo.payLink = link
    return this
  }
  setPaymentProvide(provide: PaymentProviderTypesType): this {
    this.payInfo.paymentProvide = provide
    return this
  }
  build() {
    return this.payInfo
  }
}

export class PayInfoBuilderImplementation extends PayInfoBuilder {
  constructor() {
    super();
  }
}