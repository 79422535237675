import axios from 'axios';
import store from '@/store';

const AXIOS_CONFIG = {
  baseURL: process.env.VUE_APP_NOTIFICATION_API,
  headers: {
    'Content-Type': 'application/json',
  },
};

const httpClient = axios.create(AXIOS_CONFIG);

httpClient.interceptors.response.use(
  (response) => response,
  (error) => {
    const config = error.config;
    console.error(`[${config.method.toUpperCase()}] ${config.url} error: ${error}`);
    return Promise.reject(error);
  },
);
const getNotifications = () =>
  httpClient.get('', {
    headers: {
      Authorization: `Bearer ${store.getters.isPersonalAccount ? store.state.accessToken : store.state.teams.teamToken}`,
    },
  });

const editNotificationTrigger = (triggerId: string, recaptchaToken: string, balance: number) => {
  return httpClient.put(
    `trigger/`,
    { 'trigger_id': triggerId, balance },
    {
      headers: {
        Authorization: `Bearer ${store.getters.isPersonalAccount ? store.state.accessToken : store.state.teams.teamToken}`,
        'recaptcha-token': recaptchaToken,
      },
    },
  );
};

const editNotificationDestination = (recaptchaToken: string, data: { email?: { enabled: boolean }; webhook?: { enabled?: boolean; url?: string; } }) => {
  return httpClient.put(
    `destination/`,
    { ...data },
    {
      headers: {
        Authorization: `Bearer ${store.getters.isPersonalAccount ? store.state.accessToken : store.state.teams.teamToken}`,
        'recaptcha-token': recaptchaToken,
      },
    },
  );
};

const checkURL = (url: string, recaptchaToken: string) => {
  return httpClient.post(
    'url_example/',
    { url },
    {
      headers: {
        Authorization: `Bearer ${store.getters.isPersonalAccount ? store.state.accessToken : store.state.teams.teamToken}`,
        'recaptcha-token': recaptchaToken,
      },
    },
  );
};

const connectTelegram = (recaptchaToken: string) =>
  httpClient.put('telegram_connect/', {}, {
    headers: {
      Authorization: `Bearer ${store.state.accessToken}`,
      'recaptcha-token': recaptchaToken,
    },
  });

const disconnectTelegram = (recaptchaToken: string) =>
  httpClient.put('telegram_disconnect/', {}, {
    headers: {
      Authorization: `Bearer ${store.state.accessToken}`,
      'recaptcha-token': recaptchaToken,
    },
  });

const getPublicKey = () =>
  httpClient.get('key/', {
    headers: {
      Authorization: `Bearer ${store.getters.isPersonalAccount ? store.state.accessToken : store.state.teams.teamToken}`,
    },
  });

const generateNewPublicKey = (recaptchaToken: string) => {
  return httpClient.post(
    'key/',
    {},
    {
      headers: {
        Authorization: `Bearer ${store.getters.isPersonalAccount ? store.state.accessToken : store.state.teams.teamToken}`,
        'recaptcha-token': recaptchaToken,
      },
    },
  );
};

export {
  getNotifications,
  editNotificationTrigger,
  editNotificationDestination,
  checkURL,
  connectTelegram,
  disconnectTelegram,
  getPublicKey,
  generateNewPublicKey,
};
