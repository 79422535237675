import { PaymentActions, PaymentGetters, PaymentMutations, PaymentState } from '@/types/payment/store';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '@/types/store';
import { additionalServiceCreate, subscriptionCreate } from '@/entities/subscriptionCreate/api';
import { SubscriptionCreateBodyBuilderFactory } from '@/entities/subscriptionCreate/classes';
import { sseObserverPayLink } from '@/entities/sseObservers/sseObserverPayLink/api';
import { PayInfoBuilderImplementation } from '@/shared/payment/classes';

export type State = PaymentState;
export type Mutations = PaymentMutations;
export type Getters = PaymentGetters;
export type Actions = PaymentActions;

const state: () => State = () => ({
  isLoading: false,
  isVisibleExplicitConsentForSafariModal: false,
  nowpaymantsLink: '',
  payInfo: new PayInfoBuilderImplementation().build(),
  ticketForEventSource: '',
  subscriptionCreateBody: SubscriptionCreateBodyBuilderFactory.createBuilder('plan'),
});

const getters: GetterTree<State, RootState> & Getters = {};

const mutations: MutationTree<State> & Mutations = {
  setPayInfo(state, payInfo) {
    state.payInfo = payInfo;
  },
  setDisplayConsentForSafariModal(state: State, payload: boolean) {
    state.isVisibleExplicitConsentForSafariModal = payload
  },
  setNowPayments(state, payload) {
    state.nowpaymantsLink = payload
  },
  setTicketForEventSource(state, ticket) {
    state.ticketForEventSource = ticket;
  },
  setSubscriptionCreateBody(state, subscriptionCreateBody) {
    state.subscriptionCreateBody = subscriptionCreateBody;
  },
  setIsLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
};
const actions: ActionTree<State, RootState> & Actions = {
  async getTicketForEventSource({ state, commit }, recaptchaToken) {
    try {
      commit('setIsLoading', true);
      if (!state.subscriptionCreateBody) return;
      const createConfig = state.subscriptionCreateBody.build()
      if (createConfig.service.product_type === 'request_package') {
        const { data } = await additionalServiceCreate(createConfig, recaptchaToken)
        commit('setTicketForEventSource', data.ticket_get_payment_link);
      } else {
        const { data } = await subscriptionCreate(createConfig, recaptchaToken);
        commit('setTicketForEventSource', data.ticket_get_payment_link);
      }
    } catch (e) {
      console.log('getTicketForEventSource error');
      commit('setIsLoading', false);
    }
  },
  async sseGetPaylink({ state, commit }) {
    sseObserverPayLink(state.ticketForEventSource)
      .then(event => {
        const payInfo = new PayInfoBuilderImplementation()
          .setPayLink(event as string)
          .setPaymentProvide(state.subscriptionCreateBody.build().payment_provider);
        commit('setPayInfo', payInfo.build());
      })
      .catch(error => {
        console.log(error, 'sseGetPaylinkError');
      })
      .finally(() => {
        commit('setIsLoading', false);
      });
  },
};

const PaymentModule: Module<State, RootState> = {
  state,
  mutations,
  getters,
  actions,
};

export default PaymentModule;