export const sseObserverPayLink = (ticket: string) => new Promise((resolve, reject) => {
  const eventSourceUrl = `${process.env.VUE_APP_API_URL_V6}order-pub/paylink?ticket=${ticket}`;
  const eventSource = new EventSource(eventSourceUrl);
  eventSource.addEventListener('paylink', (event) => {
    const messageEvent = event as MessageEvent<string>
    resolve(messageEvent.data);
    eventSource.close();
  })
  eventSource.onerror = function(error) {
    eventSource.close();
    reject(error);
  };
});