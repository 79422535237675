import * as amplitude from '@amplitude/analytics-browser';
import { EventsOptionType, EventsType, IFunctionOverloadingSendEvent } from '@/types/composables/analytics/shared';
export function useAmplitude() {
  // Record a specific user for correct statistics
  const setUserAmplitude = (uiId: string) => {
    amplitude.setUserId(uiId);
  }
  // We use it when the user logs out
  const resetUserAmplitude = () => {
    amplitude.reset();
  }
  // We add events through types. If the event contains any data,
  // then we add it to the eventWithOption file,
  // also add the data interface and in the EventsOptionType type we bind the interface to the event type
  const sendEventAmplitude: IFunctionOverloadingSendEvent = async <T extends EventsType>(event: T, options?: EventsOptionType<T>) => {
    amplitude.track(event, options)
  }

  return {
    setUserAmplitude,
    resetUserAmplitude,
    sendEventAmplitude
  }
}