import {
  IAdditionalServiceCreateBody,
  IDedicatedSubscriptionCreateBody,
  ISharedSubscriptionCreateBody,
  ISubscriptionCreateBody,
} from '@/entities/subscriptionCreate/types';
import { PaymentModalTypesType, PaymentProviderTypesType } from '@/shared/payment/types';
import { PeriodTypesType, ProductTypesType } from '@/shared/service/types';
import { RequestsPackId, StartPlanProductId, UnlimitedPlanProductId } from '@/shared/products/types';
import { IDedicatedNodeConfiguration } from '@/shared/dedicatedNodeConfiguration/types';

abstract class SubscriptionCreateBodyBase<T extends ISubscriptionCreateBody> {
  protected subscriptionCreateBody!: T

  initSubscriptionCreateBodyBase(): this {
    this.subscriptionCreateBody = {
      payment_provider: 'paddle',
      payment_model: 'recurrent'
    } as T
    return this
  }
  setPaymentProvider(paymentProvider: PaymentProviderTypesType): this {
    // TODO: because nowpayments
    if (paymentProvider === 'nowpayments') this.subscriptionCreateBody.payment_model = 'onetime'
    this.subscriptionCreateBody.payment_provider = paymentProvider
    return this
  }
  setPaymentModel(paymentModel: PaymentModalTypesType): this {
    // TODO: because nowpayments
    if (this.subscriptionCreateBody.payment_provider === 'nowpayments') this.subscriptionCreateBody.payment_model = 'onetime'
    else this.subscriptionCreateBody.payment_model = paymentModel
    return this
  }
  build(): T {
    return this.subscriptionCreateBody
  }
}

export class SubscriptionCreateBodyAdditionalService extends SubscriptionCreateBodyBase<IAdditionalServiceCreateBody> {
  constructor() {
    super();
    this.init()
  }
  init() {
    this.initSubscriptionCreateBodyBase()
    this.subscriptionCreateBody = {
      ...this.subscriptionCreateBody,
      service: {
        product_type: 'request_package',
        id: 'request_packages/p1000000',
        period: '1m'
      },
      subscription_id: 'subscription/'
    }
  }
  setProductType(productType: 'request_package'): this {
    this.subscriptionCreateBody.service.product_type = productType
    return this
  }
  setId(id: RequestsPackId): this {
    this.subscriptionCreateBody.service.id = id
    return this
  }
  setSubscriptionId(subscriptionId: `subscription/${string}`): this {
    this.subscriptionCreateBody.subscription_id = subscriptionId
    return this
  }
  build(): IAdditionalServiceCreateBody {
    delete this.subscriptionCreateBody.payment_model
    return this.subscriptionCreateBody
  }
}
export class SubscriptionCreateBodyShared extends SubscriptionCreateBodyBase<ISharedSubscriptionCreateBody> {
  constructor() {
    super();
    this.init()
  }
  init() {
    this.initSubscriptionCreateBodyBase()
    this.subscriptionCreateBody = {
      ...this.subscriptionCreateBody,
      service: {
        period: '1m',
        product_type: 'plan',
        id: 'plans/start5m200'
      }
    }
  }
  setPeriod(period: PeriodTypesType): this {
    this.subscriptionCreateBody.service.period = period
    return this
  }
  setProductType(productType: 'plan'): this {
    this.subscriptionCreateBody.service.product_type = productType
    return this
  }
  setId(id: StartPlanProductId | UnlimitedPlanProductId): this {
    this.subscriptionCreateBody.service.id = id
    return this
  }
  build(): ISharedSubscriptionCreateBody {
    return this.subscriptionCreateBody
  }
}
export class SubscriptionCreateBodyDedicated extends SubscriptionCreateBodyBase<IDedicatedSubscriptionCreateBody> {
  constructor() {
    super();
    this.init()
  }
  init() {
    this.initSubscriptionCreateBodyBase();
    this.subscriptionCreateBody = {
      ...this.subscriptionCreateBody,
      service: {
        period: '1m',
        product_type: 'dedicated_node',
        dedicated_node_configuration: {
          protocol: '',
          network: '',
          sync_mode: 'full',
          client: '',
          apis: [],
          addons: null,
        }
      }
    }
  }
  setPeriod(period: PeriodTypesType): this {
    this.subscriptionCreateBody.service.period = period
    return this
  }
  setProductType(productType: 'dedicated_node'): this {
    this.subscriptionCreateBody.service.product_type = productType
    return this
  }
  setDedicatedNodeConfiguration(dedicatedNodeConfiguration: IDedicatedNodeConfiguration): this {
    this.subscriptionCreateBody.service.dedicated_node_configuration = dedicatedNodeConfiguration
    return this
  }
  build() {
    return this.subscriptionCreateBody
  }
}

export class SubscriptionCreateBodyBuilderFactory {
  static createBuilder(productType: 'plan'): SubscriptionCreateBodyShared;
  static createBuilder(productType: 'request_package'): SubscriptionCreateBodyAdditionalService;
  static createBuilder(productType: 'dedicated_node'): SubscriptionCreateBodyDedicated;
  static createBuilder(productType: ProductTypesType) {
    if (productType === 'plan') {
      const builder = new SubscriptionCreateBodyShared()
      builder.setProductType(productType)
      return builder
    } else if (productType === 'dedicated_node') {
      const builder = new SubscriptionCreateBodyDedicated()
      builder.setProductType(productType)
      return builder
    } else {
      const builder = new SubscriptionCreateBodyAdditionalService()
      builder.setProductType(productType)
      return builder
    }
  }
}