import {
  SubscriptionsActions,
  SubscriptionsGetters,
  SubscriptionsMutations,
  SubscriptionsState,
} from '@/types/subscriptions/store';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '@/types/store';
import { fetchSubscriptions } from '@/entities/subscriptions/api';

export type State = SubscriptionsState;
export type Mutations = SubscriptionsMutations;
export type Getters = SubscriptionsGetters;
export type Actions = SubscriptionsActions;

const state: () => State = () => ({
  isSubscriptionsLoading: true,
  subscriptionsList: [],
});

const getters: GetterTree<State, RootState> & Getters = {
  getPlanPeriod(state) {
    const activeSubscription = state.subscriptionsList.find((subscription) => {
      return subscription.status === 'active'
    })
    const period = activeSubscription?.service.period.split('m')[0]
    return Number(period)
  }
};

const mutations: MutationTree<State> & Mutations = {
  setSubscriptionsLoading(state, loading) {
    state.isSubscriptionsLoading = loading;
  },
  setSubscriptions(state, subscriptions) {
    state.subscriptionsList = subscriptions;
  },
};

const actions: ActionTree<State, RootState> & Actions = {
  async getSubscriptions({ commit }) {
    try {
      commit('setSubscriptionsLoading', true);
      const { data } = await fetchSubscriptions();
      commit('setSubscriptions', data)
    } catch (e) {
      console.error('get Subscriptions Error');
    } finally {
      commit('setSubscriptionsLoading', false);
    }
  },
};

const SubscriptionsModule: Module<State, RootState> = {
  state,
  mutations,
  getters,
  actions,
};

export default SubscriptionsModule;