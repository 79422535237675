import { NameCookie } from '@/types/composables/useCookie';

export function useCookie() {

  function getCookie<T extends NameCookie | string>(name: T) {
    const matches = document.cookie.match(
      new RegExp('(?:^|; )' + name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + '=([^;]*)'),
    );
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  // eslint-disable-next-line
  function setCookie<T extends NameCookie | string>(name: T, value: string, options: any = {}) {
    options = {
      path: '/',
      ...options,
    };
    if (options.expires instanceof Date) options.expires = options.expires.toUTCString();
    let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value);
    for (const optionKey in options) {
      updatedCookie += '; ' + optionKey;
      const optionValue = options[optionKey];
      if (optionValue !== true) {
        updatedCookie += '=' + optionValue;
      }
    }
    document.cookie = updatedCookie;
  }

  function deleteCookie<T extends NameCookie | string>(name: T, domain: string = '') {
    if (domain === '') {
      setCookie<T>(name, '', {
        'max-age': -1,
      });
      return;
    }
    setCookie<T>(name, '', {
      domain,
      'max-age': -1,
    });
  }

  const deleteAllCookies = (exceptions: string[] = []) => {
    const allCookies = document.cookie.split(';');
    allCookies.forEach(i => {
      const separator = i.indexOf('=');
      const cookieName = separator > -1 ? i.substring(0, separator) : i;
      if (!exceptions.includes(cookieName)) deleteCookie<string>(cookieName);
    });
  };

  return { getCookie, setCookie, deleteCookie, deleteAllCookies };
}
