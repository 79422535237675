import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { ProfileActions, ProfileGetters, ProfileMutations, ProfileState } from '@/types/profile/store';
import { RootState } from '@/types/store';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { recoverPassword } from '@/api/auth';
import { changeUserData, getUserData } from '@/api/user';
import { useNotification } from '@/composables/useNotification';
import { AxiosResponse } from 'axios';
import { UserData } from '@/types/user/shared';
import { accountsCreatedAfterUpdate } from '@/utils/globalVariables'

export type State = ProfileState;
export type Mutations = ProfileMutations;
export type Getters = ProfileGetters;
export type Actions = ProfileActions;

const state: () => State = () => ({
  userData: {
    name: '',
    email: '',
    masked_email: '',
    create_at: '',
    is_referral_member: false,
  },
  modals: {
    showLogoutModal: false,
    showResetPassword: false,
    showERC20Modal: false,
    showPayoutSuccessModal: false,
    showConnectTelegramModal: false,
  },
  loading: {
    resetPassword: false,
  },
  availablePackages: [],
  accountType: 'email',
  currentSubscriptionDetails: {
    type: '',
    protocol: '',
    volume: 0,
    rps: 0,
    price: 0,
    period: '',
    nextPaymentDate: '',
  }
});

const getters: GetterTree<State, RootState> & Getters = {};

const mutations: MutationTree<State> & Mutations = {
  setUserData(state, userData) {
    state.userData = userData;
  },
  setShowLogoutModal(state, show) {
    state.modals.showLogoutModal = show;
    changeScrollState('logoutModal', show);
  },
  setShowResetPasswordModal(state, show) {
    state.modals.showResetPassword = show;
    changeScrollState('resetPassword', show);
  },
  setERC20Modal(state, show) {
    state.modals.showERC20Modal = show;
    changeScrollState('bep20Modal', show);
  },
  setShowPayoutSuccessModal(state, show) {
    state.modals.showPayoutSuccessModal = show;
    changeScrollState('referralSuccessModal', show);
  },
  setShowConnectTelegramModal(state, show) {
    state.modals.showConnectTelegramModal = show;
    changeScrollState('connectTelegram', show);
  },
  setLoading(state, { field, loading }) {
    state.loading[field] = loading;
  },
  setAccountType(state, type) {
    state.accountType = type;
  },
  setCurrentSubscriptionDetails(state, details) {
    state.currentSubscriptionDetails = details;
  }
};

const changeScrollState = (elementId: string, show: boolean) => {
  const el = document.getElementById(elementId) as Element;
  if (show) disableBodyScroll(el);
  else enableBodyScroll(el);
};

const actions: ActionTree<State, RootState> & Actions = {
  async resetPassword({ commit, state }, recaptchaToken) {
    try {
      commit('setLoading', { field: 'resetPassword', loading: true });
      await recoverPassword(state.userData.email, recaptchaToken);
      commit('setShowResetPasswordModal', true);
    } catch (e) {
      const { errorData } = useNotification();
      commit('setNotificationMessage', { ...errorData('user_change-account-information'), type: 'error' });
      commit('setNotificationMessageDisplay', true);
    } finally {
      commit('setLoading', { field: 'resetPassword', loading: false });
    }
  },
  async setUserData({ commit }) {
    try {
      const res: AxiosResponse<UserData> = await getUserData();
      if (!res.data.name || res.data.name === 'null') res.data.name = 'GetBlock User';
      commit('setUserData', res.data);
    } catch (e) {
      const { errorData } = useNotification();
      commit('setNotificationMessage', { ...errorData('user_get-account-information'), type: 'error' });
      commit('setNotificationMessageDisplay', true);
    }
  },
  async changeUserData({ commit, state }, { name, recaptchaToken }) {
    try {
      await changeUserData(name, state.userData.email, recaptchaToken);
      commit('setUserData', {
        name: name,
        email: state.userData.email,
      });
    } catch (e) {
      const { errorData } = useNotification();
      commit('setNotificationMessage', { ...errorData('user_change-account-information'), type: 'error' });
      commit('setNotificationMessageDisplay', true);
    }
  },
};

const ProfileModule: Module<State, RootState> = {
  state,
  mutations,
  getters,
  actions,
};

export default ProfileModule;
