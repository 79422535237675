import axios from 'axios';
import store from '@/store';

const AXIOS_CONFIG = {
  baseURL: process.env.VUE_APP_ROUTES_API,
  headers: {
    'Content-Type': 'application/json',
  },
};

const httpClient = axios.create(AXIOS_CONFIG);

httpClient.interceptors.request.use(
  (config) => {
    config.headers['Authorization'] = `Bearer ${store.getters.isPersonalAccount ? store.state.accessToken : store.state.teams.teamToken}`;
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
);

httpClient.interceptors.response.use(
  (response) => response,
  (error) => {
    const config = error.config;
    console.error(`[${config.method.toUpperCase()}] ${config.url} error: ${error}`);
    return Promise.reject(error);
  },
);

const rollDedicatedEndpoint = (id: string, recaptchaToken: string) =>
  httpClient.put(`${id}/`, {}, {
    headers: {
      'recaptcha-token': recaptchaToken,
    },
  });

const deleteDedicatedEndpoint = (id: string, recaptchaToken: string) => {
  return httpClient.delete(
    `${id}/`,
    {
      headers: {
        'recaptcha-token': recaptchaToken,
      },
    },
  );
};

const updateDedicatedNode = (id: string, data: { [field: string]: string }, recaptchaToken: string) =>
  httpClient.post(
    '',
    { dn_id: id, ...data },
    {
      headers: {
        'recaptcha-token': recaptchaToken,
      },
    },
  );

const renameDedicatedEndpoint = (id: string, name: string, recaptchaToken: string) =>
  httpClient.put('', {
    id,
    name,
  }, {
    headers: {
      'recaptcha-token': recaptchaToken,
    },
  });

export {
  rollDedicatedEndpoint,
  deleteDedicatedEndpoint,
  updateDedicatedNode,
  renameDedicatedEndpoint,
};
