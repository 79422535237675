import {
  PaymentHistoryActions,
  PaymentHistoryGetters,
  PaymentHistoryMutations,
  PaymentHistoryState,
} from '@/types/paymentHistory/store';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '@/types/store';
import { fetchPaymentHistory } from '@/entities/paymentHistory/api';
import { useNotification } from '@/composables/useNotification';

export type State = PaymentHistoryState;
export type Mutations = PaymentHistoryMutations;
export type Getters = PaymentHistoryGetters;
export type Actions = PaymentHistoryActions;

const state: () => State = () => ({
  paymentHistory: []
});

const getters: GetterTree<State, RootState> & Getters = {};

const mutations: MutationTree<State> & Mutations = {
  setPaymentHistory(state, payload) {
    state.paymentHistory = payload
  }
};
const actions: ActionTree<State, RootState> & Actions = {
  async requestPaymentHistory({ commit }) {
    try {
      const { data } = await fetchPaymentHistory();
      commit('setPaymentHistory', data.filter((data) => data.status !== 'created'))
    } catch (e) {
      const { errorData } = useNotification();
      commit('setNotificationMessage', { ...errorData('user_get-payment-history'), type: 'error' });
      commit('setNotificationMessageDisplay', true);
    }
  }
};

const PaymentHistoryModule: Module<State, RootState> = {
  state,
  mutations,
  getters,
  actions,
};

export default PaymentHistoryModule;