<template>
  <div
    class="modal fixed z-20 top-0 left-0 h-full w-full flex justify-center items-center xs:items-end xs:left-0 xs:right-0"
    :id="props.id"
    @click.self="closeModal"
  >
    <div
      class="
        modal__content
        bg-bg-base
        dark:bg-bg-dark-base
        relative
        overflow-auto
        flex flex-col
        xs:w-full
        xs:rounded-b-none
        gap-8
        xs:gap-6
      "
      :class="`${props.rounded} ${props.paddings} ${props.additionalClasses}`"
      :style="[additionalStyles]"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { defineEmits, defineProps } from 'vue';

const emit = defineEmits(['close']);

const props = defineProps({
  id: {
    type: String,
    default: '',
  },
  rounded: {
    type: String,
    default: 'rounded-16',
  },
  paddings: {
    type: String,
    default: 'p-8 xs:px-4 xs:py-6',
  },
  additionalClasses: {
    type: String,
    default: '',
  },
  additionalStyles: {
    type: String,
    default: '',
  },
});

const closeModal = () => {
  emit('close');
};


</script>


<style lang="scss" scoped>
.modal {
  backdrop-filter: blur(3px);
  background: rgba(0, 0, 0, 0.24);

  &-content {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.modal__content {
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.025);
}
</style>
