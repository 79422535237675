import { IOnboardingActions, IOnboardingMutations, IOnboardingState } from '@/types/onboarding/store';
import { ActionTree, Module, MutationTree } from 'vuex';
import { RootState } from '@/types/store';
import { IEndpointData } from '@/types/onboarding/shared';

export type State = IOnboardingState;
export type Mutations = IOnboardingMutations;
export type Actions = IOnboardingActions;

const state: () => State = () => ({
  showModalPaymentOnboarding: false,
  showAccessDeniedModalOnboarding: false,
  generalData: {
    whoBuilding: 'company',
    teamName: '',
    typeOfProject: 'defi',
  },
  endpoint: {
    protocol: '',
    network: '',
    api: '',
    addon: '',
    namespace: 'shared',
  },
  region: 'europe',
  paySuccess: ''
});

const mutations: MutationTree<State> & Mutations = {
  setPay(state: State, payload) {
    state.paySuccess = payload
  },
  setGeneralData(state: State, payload) {
    state.generalData = payload
  },
  setEndpointData(state, endpoint) {
    state.endpoint = endpoint
  },
  setRegion(state: State, payload) {
    state.region = payload
  },
  setShowModalPaymentOnboarding(state, payload) {
    state.showModalPaymentOnboarding = payload
  },
  setShowAccessDeniedModalOnboarding(state, payload){
    state.showAccessDeniedModalOnboarding = payload
  }
};

const actions: ActionTree<State, RootState> & Actions = {}

const onboardingModule: Module<State, RootState> = {
  state,
  mutations,
  actions,
}

export default onboardingModule