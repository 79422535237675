import { useGtm } from '@gtm-support/vue-gtm';
import { GoogleAnalyticsEvents } from '@/types/composables/useGoogleAnalytics';

export function useGoogleAnalytics() {
  const gtm = useGtm();

  const sendGoogleAnalytics = (eventName: GoogleAnalyticsEvents) => {
    gtm?.trackEvent({
      event: eventName
    })
  }

  return { sendGoogleAnalytics }
}
