import { httpClientV3 } from '@/shared/api/httpClient';
import store from '@/store';
import { AxiosResponse } from 'axios';
import { IAccessTokenDedicated, IAccessTokenShared } from '@/entities/accessToken/types';

const fetchEndpoints = () => httpClientV3.get('access_tokens/', {
  headers: {
    Authorization: `Bearer ${store.getters.isPersonalAccount ? store.state.accessToken : store.state.teams.teamToken}`,
  },
}) as Promise<AxiosResponse<(IAccessTokenShared | IAccessTokenDedicated)[]>>

export  {
  fetchEndpoints
}