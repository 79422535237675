import { IBalances } from '@/entities/balances/types';

abstract class BalancesBuilder {
  protected balances!: IBalances
  protected constructor() {
    this.init()
  }
  init(): this {
    this.balances = {
      free: null,
      locked: null,
      main: null,
      getBalance() {
        return  this.main ? this.main : this.free
      }
    }
    return this
  }

  setFree(free: number | null): this {
    this.balances.free = free
    return this
  }
  setLocked(locked: number | null): this {
    this.balances.locked = locked
    return this
  }
  setMain(main: number | null): this {
    this.balances.main = main
    return this
  }
  build() {
    return this.balances
  }
}

export class BalancesBuilderImplementation extends BalancesBuilder {
  constructor() {
    super();
  }
}