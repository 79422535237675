import { httpClientV6 } from '@/api';
import store from '@/store';
import { AxiosResponse } from 'axios';
import { IBalancesResponse } from '@/entities/balances/types';

const fetchBalance = () => httpClientV6.get('balance/balances', {
  headers: {
    Authorization: `Bearer ${store.getters.isPersonalAccount ? store.state.accessToken : store.state.teams.teamToken}`,
  },
}) as Promise<AxiosResponse<IBalancesResponse>>

export {
  fetchBalance
}