// @ts-ignore
import mixpanel from 'mixpanel-browser';
import { EventsOptionType, EventsType, IFunctionOverloadingSendEvent } from '@/types/composables/analytics/shared';
export function useMixpanel() {
  // Record a specific user for correct statistics
  const setUserMixpanel = (uiId: string) => {
    mixpanel.identify(uiId)
  }
  // We use it when the user logs out
  const resetUserMixpanel = () => {
    mixpanel.reset();
  }
  // We add events through types. If the event contains any data,
  // then we add it to the eventWithOption file,
  // also add the data interface and in the EventsOptionType type we bind the interface to the event type
  const sendEventMixpanel: IFunctionOverloadingSendEvent = async <T extends EventsType>(event: T, options?: EventsOptionType<T>) => {
    mixpanel.track(event, options)
  }

  return {
    setUserMixpanel,
    resetUserMixpanel,
    sendEventMixpanel
  }
}