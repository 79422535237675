import { httpClientV6 } from '@/shared/api/httpClient';
import store from '@/store';
import { AxiosResponse } from 'axios';
import { IDedicatedOrderFromPaymentHistory, ISharedOrderFromPaymentHistory } from '@/entities/paymentHistory/types';

const fetchPaymentHistory = () => httpClientV6.get('/order/', {
  headers: {
    Authorization: `Bearer ${store.getters.isPersonalAccount ? store.state.accessToken : store.state.teams.teamToken}`,
  },
}) as Promise<AxiosResponse<(ISharedOrderFromPaymentHistory | IDedicatedOrderFromPaymentHistory)[]>>;

export {
  fetchPaymentHistory
}