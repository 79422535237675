import axios from 'axios';
import store from '@/store';
import { Permissions } from '@/types/teams/teams';

const AXIOS_CONFIG = {
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
};

const httpClient = axios.create(AXIOS_CONFIG);

httpClient.interceptors.request.use(
  (config) => {
    config.headers['Authorization'] = `Bearer ${store.state.accessToken}`;
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
);

httpClient.interceptors.response.use(
  (response) => response,
  (error) => {
    const config: { method: string; url: string } = error.config;
    console.error(`[${config.method.toUpperCase()}] ${config.url} error: ${error}`);
    return Promise.reject(error);
  },
);

const getTeamList = () => httpClient.get('/team/');

const getInviteList = () => httpClient.get('/team/invite/');

const getCurrentTeam = (team_id: string) => httpClient.get(`/team/${team_id}/`);

const getIsAllowedCreateTeam = () => httpClient.get('/team/allow/');

const createNewTeam = (name: string, recaptchaToken: string) =>
  httpClient.post(
    '/team/',
    {
      name
    },
    {
      headers: {
        'recaptcha-token': recaptchaToken,
      },
    },
  );

const teamAuth = (team_id: string, recaptchaToken: string) =>
  httpClient.post(
    '/team/auth/',
    {
      team_id
    },
    {
      headers: {
        'recaptcha-token': recaptchaToken,
      },
    },
  );

const sendInvite = (team_id: string, invited: string, name: string, recaptchaToken: string ) =>
  httpClient.post(
    '/team/invite/',
    {
      team_id,
      invited,
      name
    },
    {
      headers: {
        'recaptcha-token': recaptchaToken,
      },
    },
  );

const respondInvite = (team_id: string, is_accept: boolean, recaptchaToken: string ) =>
  httpClient.put(
    '/team/invite/',
    {
      team_id,
      is_accept
    },
    {
      headers: {
        'recaptcha-token': recaptchaToken,
      },
    },
  );

const renameTeam = (name: string, id: string, recaptchaToken: string ) =>
  httpClient.put('/team/', {
    name,
    team_id: id
  }, {
    headers: {
      'recaptcha-token': recaptchaToken,
    },
  });

const setPermissions = (team_id: string, uid_to_manage: string, is_admin: boolean, permissions: Permissions, recaptchaToken: string) =>
  httpClient.put(
    '/team/permissions/',
    {
      team_id,
      uid_to_manage,
      is_admin,
      permissions
    },
    {
      headers: {
        'recaptcha-token': recaptchaToken,
      },
    },
  );

const removeMember = (uid_to_manage: string, team_id: string, recaptchaToken: string) =>
  httpClient.delete(
    '/team/members/',
    {
      data: {
        uid_to_manage,
        team_id
      },
      headers: {
        'recaptcha-token': recaptchaToken,
      },
    },
  );

const renameMember = (name: string, id: string, recaptchaToken: string ) =>
  httpClient.put('/team/members/', {
    name,
    team_id: id
  }, {
    headers: {
      'recaptcha-token': recaptchaToken,
    },
  });

export {
    getTeamList,
    getInviteList,
    getCurrentTeam,
    getIsAllowedCreateTeam,
    createNewTeam,
    teamAuth,
    sendInvite,
    respondInvite,
    renameTeam,
    setPermissions,
    removeMember,
    renameMember
};
