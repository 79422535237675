import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { getPriceAndEstimate } from '@/api/nodeOrder';
import {
  DedicatedNodeConfiguratorActions,
  DedicatedNodeConfiguratorGetters, DedicatedNodeConfiguratorMutations,
  DedicatedNodeConfiguratorState,
} from '@/types/dedicatedNodeConfigurator/store';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '@/types/store';
import { Periods } from '@/types/dedicatedNodeConfigurator/steps/planStep';
import { useNotification } from '@/composables/useNotification';
import { Node } from '@/types/shared/shared';
import { NetworkConfig } from '@/types/dedicatedNodeConfigurator/shared';

export type State = DedicatedNodeConfiguratorState;
export type Mutations = DedicatedNodeConfiguratorMutations;
export type Getters = DedicatedNodeConfiguratorGetters;
export type Actions = DedicatedNodeConfiguratorActions;

const state: () => State = () => ({
  navbarSteps: [
    {
      name: 'Protocol',
      description: 'Select protocol',
      imgPath: 'cube-01',
      checked: true,
      disabled: false,
    },
    {
      name: 'Plan',
      description: 'Choose plan',
      imgPath: 'calendar',
      checked: false,
      disabled: true,
    },
    {
      name: 'Settings',
      description: 'Set up node',
      imgPath: 'settings-01',
      checked: false,
      disabled: true,
    },
    {
      name: 'Payment',
      description: 'Checkout',
      imgPath: 'bank-note-01',
      checked: false,
      disabled: true,
    },
  ],
  stepNames: ['Protocol', 'Plan', 'Settings', 'Payment'],
  protocols: [],
  availableProtocols: [],
  selectedData: {
    signature: '',
    protocol: {
      text: '',
      ticker: '',
      imgPath: '',
    },
    plan: {
      value: '',
      imgPath: '',
      period: '',
    },
    network: {
      text: '',
      value: '',
    },
    mode: '',
    client: '',
    apis: [],
    addons: [],
    priceValue: 1,
    price: 0,
    save: 0,
    eta: '',
  },
  showDiscountModal: false,
  showCancelOrderModal: false,
  showDeploymentTimeModal: false,
  showBEP20Modal: false,
  isProlongation: false,
});
const getters: GetterTree<State, RootState> & Getters = {
  getNextStep: (state) => (currentStep) => state.stepNames[state.stepNames.indexOf(currentStep) + 1],
  getPrevStep: (state) => (currentStep) => state.stepNames[state.stepNames.indexOf(currentStep) - 1],
  isPossibleToChangeStep: (state) => (nextStep) => {
    const
      data = state.selectedData;
    if (nextStep === 'Protocol') {
      return true;
    }
    if (nextStep === 'Plan') {
      return Boolean(data.protocol.ticker);
    }
    if (nextStep === 'Settings') {
      return Boolean(data.plan.value);
    }
    return Boolean(
      state.selectedData.network
      && state.selectedData.mode
      && state.selectedData.client
      && (state.selectedData.apis.length > 0 || state.selectedData.addons.length > 0),
    );
  },
  configs(state, getters) {
    const configs = {};
    // @ts-ignore
    getters.nodes.forEach((node: Node) => {
      const nodeNetworks = Array.from(new Set(Object.keys(node.networks))).filter((network) => node.networks[network].service_levels.find((serviceLevel) => serviceLevel._key === 'dedicated'));
      nodeNetworks.forEach((network) => {
        const rawNodeModes = node.networks[network].service_levels.find((serviceLevel) => serviceLevel._key === 'dedicated')!.modes;
        const nodeModes = rawNodeModes.map((mode) => mode._key);
        nodeModes.forEach((mode) => {
          // @ts-ignore
          configs[node.protocol._key] = {
            // @ts-ignore
            ...(configs[node.protocol._key] ? configs[node.protocol._key] : {}),
            [network]: {
              addons: node.networks[network]?.service_levels?.find((serviceLevel) => serviceLevel._key === 'dedicated')?.addons?.reduce((acc, item) =>
                ({
                  ...acc,
                  [item._key]: item.name,
                }), {}),
              apis: Object.keys(node.networks[network].apis).reduce((acc, item) => ({
                ...acc,
                [item]: node.networks[network].apis[item].name,
              }), {}),
              name: node.networks[network].name,
              modes: {
                // @ts-ignore
                ...(configs[node.protocol._key] && Object.keys(configs[node.protocol._key])?.includes(network) ? configs[node.protocol._key][network]?.modes : {}),
                [mode]: rawNodeModes.find((currentMode) => currentMode._key === mode)!.clients,
              },
            },
          };
        });
      });
    });
    return configs;
  },
  protocols(state, getters) {
    // @ts-ignore
    return getters.nodes.map((node) => {
      const dedicatedNodes = Object.keys(getters.configs);
      return {
        text: node.protocol.name,
        ticker: node.protocol._key,
        imgPath: `coins/${node.protocol._key}`,
        dedicatedAvailability: dedicatedNodes.includes(node.protocol._key),
      };
    });
  },
  availableNetworks(state, getters) {
    const config = getters.configs[state.selectedData?.protocol?.ticker as keyof typeof getters.configs] as NetworkConfig;
    if (!config) {
      return [];
    }

    const setOfNetworks = Array.from(new Set(Object.keys(config)));

    return setOfNetworks.map((network) => ({
      text: config[network].name,
      value: network,
    }));
  },
  availableModes(state, getters) {
    const config = getters.configs[state.selectedData.protocol.ticker as keyof typeof getters.configs] as NetworkConfig;
    if (!config) {
      return [];
    }

    const setOfModes = Array.from(
      new Set(
        Object.keys(config)
          .map((network) => Object.keys(config[network].modes))
          .reduce((acc, modes) => [...acc, ...modes], []),
      ));

    const currentConfigSetOfModes = Object.keys(config[state.selectedData.network.value].modes);

    function getTitle(title: string) {
      title = state.selectedData.protocol.ticker === "sol-pro" && title === 'Full' ? "Full (Program ID)" : title
      if (title === "Full+spl_token_owner") {
        title = 'Full (Program ID + SPL Token owner)'
      }
      if (title === "Full+spl_token_mint") {
        title = 'Full (Program ID + SPL Token Mint)'
      }
      if (title === "Full+spl_token_owner+spl_token_mint") {
        title = 'Full (Program ID + SPL Token owner + SPL Token Mint)'
      }

      return title;
    }

    return setOfModes.map((mode) => ({
      title: getTitle(mode[0].toUpperCase() + mode.slice(1)),
      value: mode,
      subtitle: 'Mode',
      imgPath: `dedicatedNodeConfigurator/steps/settingsStep/${mode}-mode.svg`,
      selected: mode === state.selectedData.mode,
      disabled: !currentConfigSetOfModes.includes(mode),
    }));
  },
  availableClients(state, getters) {
    const config = getters.configs[state.selectedData.protocol.ticker as keyof typeof getters.configs] as NetworkConfig;
    if (!config) {
      return [];
    }

    const setOfClients = Array.from(
      new Set(
        Object.keys(config)
          .map((network) => Object.values(config[network].modes))
          .reduce((acc: { _key: string }[], modes) => [...acc, ...modes], [])
          // @ts-ignore
          .reduce((acc: { _key: string }[], modes) => [...acc, ...modes], [])
          .map((mode) => mode._key)),
    );

    const currentConfigSetOfClients = Object.values(config[state.selectedData.network.value].modes[state.selectedData.mode])
      // @ts-ignore
      .map((mode) => mode._key);

    return setOfClients.map((client) => ({
      title: client,
      value: client,
      subtitle: 'Client',
      selected: client === state.selectedData.client,
      imgPath: 'dedicatedNodeConfigurator/steps/settingsStep/client.svg',
      disabled: !currentConfigSetOfClients.includes(client),
    }));
  },
  availableApis(state, getters) {
    const config = (getters.configs[state.selectedData.protocol.ticker as keyof typeof getters.configs] as NetworkConfig)[state.selectedData.network.value];
    if (!config) {
      return {
        apis: [],
        addons: [],
      };
    }

    return {
      apis: config?.apis ? Object.keys(config.apis).map((api) => ({
        title: config.apis[api as keyof typeof config.apis],
        value: api,
        selected: state.selectedData.apis.includes(api),
        disabled: false,
      })) : [],
      addons: config?.addons ? Object.keys(config.addons).map((addon) => ({
        title: config.addons[addon as keyof typeof config.addons],
        value: addon,
        selected: state.selectedData.addons.includes(addon),
        disabled: false,
      })) : [],
    };
  },
  checkoutData(state, getters) {
    const data = state.selectedData;
    return {
      protocol: {
        value: data.protocol.text,
        ticker: data.protocol.ticker,
        imgPath: data.protocol.imgPath,
      },
      plan: {
        value: data.plan.value,
        rawValue: data.plan.period as Periods,
        imgPath: data.plan.imgPath,
      },
      network: {
        // @ts-ignore
        value: getters.availableNetworks.find((network) => network.value === state.selectedData.network.value).text,
        // @ts-ignore
        rawValue: getters.availableNetworks.find((network) => network.value === state.selectedData.network.value).value,
        imgPath: 'dedicatedNodeConfigurator/steps/settingsStep/network.svg',
      },
      client: {
        // @ts-ignore
        value: getters.availableClients.find((client) => client.selected).title,
        // @ts-ignore
        imgPath: getters.availableClients.find((client) => client.selected).imgPath,
      },
      mode: {
        // @ts-ignore
        value: getters.availableModes.find((mode) => mode.selected).title,
        // @ts-ignore
        rawValue: getters.availableModes.find((mode) => mode.selected).value,
        // @ts-ignore
        imgPath: getters.availableModes.find((mode) => mode.selected).imgPath,
      },
      apis: {
        // @ts-ignore
        value: getters.availableApis.apis.filter((api) => api.selected).map((api) => api.title).join(', '),
        // @ts-ignore
        rawValue: getters.availableApis.apis.filter((api) => api.selected).map((api) => api.value),
      },
      addons: {
        // @ts-ignore
        value: getters.availableApis.addons.filter((addon) => addon.selected).map((addon) => addon.title).join(', '),
        // @ts-ignore
        rawValue: getters.availableApis.addons.filter((addon) => addon.selected).map((addon) => addon.value),
      },
    };
  },
  selectedPeriod(state) {
    return state.selectedData.plan.period || '1m';
  },
};
const mutations: MutationTree<State> & Mutations = {
  changeStep(state, step) {
    state.navbarSteps = state.navbarSteps.map((s) => ({ ...s, checked: false }));
    const foundedStep = state.navbarSteps.filter((s) => s.name === step)[0];
    foundedStep.checked = true;
    foundedStep.disabled = false;
  },
  setProtocol(state, protocol) {
    state.selectedData.protocol = protocol;
  },
  setShowDiscountModal(state, show) {
    state.showDiscountModal = show;
    changeScrollState('dedicatedNodeDiscountModal', show);
  },
  setShowCancelOrderModal(state, show) {
    state.showCancelOrderModal = show;
    changeScrollState('cancelOrderModal', show);
  },
  setShowDeploymentTimeModal(state, show) {
    state.showDeploymentTimeModal = show;
    changeScrollState('deploymentTimeModal', show);
  },
  setShowBEP20Modal(state, show) {
    state.showBEP20Modal = show;
    changeScrollState('bep20Modal', show);
  },
  setPrices(state, data) {
    state.selectedData.signature = data.signature;
    state.selectedData.price = data.price;
    state.selectedData.save = data.save;
    state.selectedData.eta = data.eta;
  },
  changePriceValue(state, value) {
    state.selectedData.priceValue = value;
  },
  setPlan(state, plan) {
    state.selectedData.plan = plan;
  },
  setNetwork(state, item) {
    state.selectedData.network = item;
    state.selectedData.apis = [];
    state.selectedData.addons = [];
  },
  setClient(state, item) {
    state.selectedData.client = item;
    state.selectedData.apis = [];
    state.selectedData.addons = [];
  },
  setMode(state, item) {
    state.selectedData.mode = item;
    state.selectedData.apis = [];
    state.selectedData.addons = [];
  },
  changeAPISelected(state, item) {
    if (state.selectedData.apis.includes(item)) {
      state.selectedData.apis = state.selectedData.apis.filter((api) => api !== item);
    } else {
      state.selectedData.apis.push(item);
    }
  },
  changeAddonSelected(state, item) {
    if (state.selectedData.addons.includes(item)) {
      state.selectedData.addons = state.selectedData.addons.filter((addon) => addon !== item);
    } else {
      state.selectedData.addons.push(item);
    }
  },
  clearConfig(state) {
    state.selectedData = {
      signature: '',
      protocol: {
        text: '',
        ticker: '',
        imgPath: '',
      },
      plan: {
        value: '',
        imgPath: '',
        period: '',
      },
      network: {
        text: '',
        value: '',
      },
      mode: '',
      client: '',
      apis: [],
      addons: [],
      priceValue: 1,
      price: 0,
      save: 0,
      eta: '',
    };
    state.showDiscountModal = false;
    state.showCancelOrderModal = false;
    state.showDeploymentTimeModal = false;
    state.showBEP20Modal = false;
    state.navbarSteps = state.navbarSteps.map((step, index) => ({
      ...step,
      disabled: index !== 0,
      checked: index === 0,
    }));
  },
  enableSteps(state, steps) {
    state.navbarSteps = state.navbarSteps.map((step) => ({
      ...step,
      disabled: !steps.includes(step.name),
    }));
  },
  setIsProlongation(state, isProlongation) {
    state.isProlongation = isProlongation;
  },
};

const actions: ActionTree<State, RootState> & Actions = {
    changeStep({ commit, getters }, nextStep) {
      // @ts-ignore
      if (getters.isPossibleToChangeStep(nextStep)) {
        commit('changeStep', nextStep);
      }
    },
    async changeConfigurationField({ commit, dispatch }, { field, value }) {
      try {
        await dispatch('changeConfiguration', { field, value });
      } catch (e) {
        const { errorData } = useNotification();
        commit('setNotificationMessage', { ...errorData('dedicated-order_calculate-price'), type: 'error' });
        commit('setNotificationMessageDisplay', true);
      }
    },
    async changeConfiguration({ commit, dispatch, getters }, { field, value }) {
      if (field === 'protocol') {
        await commit('setProtocol', value);
        // @ts-ignore
        await commit('setNetwork', getters.availableNetworks[0]);
        // @ts-ignore
        await commit('setMode', getters.availableModes.find((mode) => !mode.disabled).value);
        // @ts-ignore
        await commit('setClient', getters.availableClients.find((client) => !client.disabled).value);
      }

      if (field === 'network') {
        // @ts-ignore
        await commit('setNetwork', getters.availableNetworks.find((network) => network.value === value));
        // @ts-ignore
        await commit('setMode', getters.availableModes.find((mode) => !mode.disabled && mode.value === 'full')?.value || getters.availableModes.find((mode) => !mode.disabled).value);
        // @ts-ignore
        await commit('setClient', getters.availableClients.find((client) => !client.disabled).value);
      }

      if (field === 'mode') {
        await commit('setMode', value);
        // @ts-ignore
        await commit('setClient', getters.availableClients.find((client) => !client.disabled).value);
      }

      if (field === 'client') {
        await commit('setClient', value);
      }

      if (field === 'api') {
        await commit('changeAPISelected', value);
      }

      if (field === 'addon') {
        await commit('changeAddonSelected', value);
      }

      await dispatch('getPriceAndEstimate');
    },
    async getPriceAndEstimate({ getters, commit, state }) {
      try {
        const selectedData = state.selectedData;
        // @ts-ignore
        const response = await getPriceAndEstimate(selectedData.protocol.ticker, selectedData.network.value, selectedData.mode, selectedData.client, getters.selectedPeriod, selectedData.apis, selectedData.addons);
        commit('setPrices', {
          eta: response.data.eta,
          price: response.data.price,
          save: response.data.save,
          signature: response.headers['x-configuration-signature'],
        });
      } catch (e) {
        const { errorData } = useNotification();
        commit('setNotificationMessage', { ...errorData('dedicated-order_calculate-price'), type: 'error' });
        commit('setNotificationMessageDisplay', true);
      }
    },
  }
;

const changeScrollState = (elementId: string, show: boolean) => {
  const el = document.getElementById(elementId) as Element;
  if (show) disableBodyScroll(el);
  else enableBodyScroll(el);
};

const dedicatedNodeConfigurator: Module<State, RootState> = {
  state,
  mutations,
  getters,
  actions,
};

export default dedicatedNodeConfigurator;


