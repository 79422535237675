import { httpClientV6 } from '@/shared/api/httpClient';
import store from '@/store';
import { AxiosResponse } from 'axios';
import { IDedicatedNode } from '@/entities/dedicatedNode/types';

const fetchDedicatedNodes = () => httpClientV6.get('dedicated-nodes', {
  headers: {
    Authorization: `Bearer ${store.getters.isPersonalAccount ? store.state.accessToken : store.state.teams.teamToken}`,
  },
}) as Promise<AxiosResponse<IDedicatedNode[]>>

export {
  fetchDedicatedNodes
}