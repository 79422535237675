<script lang="ts" setup>
import ModalTemplate from '@/components/modals/ModalTemplate.vue';
import { GetBlockUIButton, GetBlockUIIcon } from '@getblockui/core';
import { computed, defineEmits, ref } from 'vue';
import { useStore } from '@/store';
import { useGtm } from '@gtm-support/vue-gtm';


const emit = defineEmits(['close', 'renameEndpoint']);
const store = useStore();
const gtm = useGtm();

type ProtocolInfoProtocolType = 'protocol';
type ProtocolInfoNetworkType = 'network';
type ProtocolInfoApiOrAddonType = 'api-addon';
type ProtocolInfosType = [ProtocolInfoProtocolType, ProtocolInfoNetworkType, ProtocolInfoApiOrAddonType];

const ProtocolInfo: {[key in ProtocolInfosType[number]]: string} = {
  protocol: store.state.general.accessTokenInfoModal.endpoint.protocol,
  network: store.state.general.accessTokenInfoModal.endpoint.network.text,
  'api-addon': store.state.general.accessTokenInfoModal.endpoint.addon.text || store.state.general.accessTokenInfoModal.endpoint.api.text || store.state.general.accessTokenInfoModal.endpoint.addon.value || store.state.general.accessTokenInfoModal.endpoint.api.value
}

const FieldsProtocolInfo: ProtocolInfosType = ['protocol', 'network', 'api-addon']

const nameInput = ref<HTMLInputElement | null>(null);
const endpointName = ref<string>(store.state.general.accessTokenInfoModal.endpoint.name);
const isRenaming = ref<boolean>(false);
const isRenamingButtonFocus = ref<boolean>(false);
const isCopied = ref<boolean>(false);

const computedUrl = computed<string>(() => {
  if (
    store.state.general.accessTokenInfoModal.endpoint.addon.value?.includes('ws') ||
    store.state.general.accessTokenInfoModal.endpoint.api.value?.includes('ws')
  ) {
    return `wss://go.getblock.io/${store.state.general.accessTokenInfoModal.endpoint._key}`;
  }
  return `https://go.getblock.io/${store.state.general.accessTokenInfoModal.endpoint._key}`;
});

const onBlur = () => {
  document.getSelection()!.removeAllRanges();
};
const handleCopyButtonClick = async (url: string) => {
  if (isCopied.value) return;
  await navigator.clipboard.writeText(url);
  isCopied.value = true;
  setTimeout(() => {
    isCopied.value = false;
  }, 3000);
};
const changeEndpointName = (event: Event) => {
  endpointName.value = (event.target as HTMLInputElement).value;
};
const startRenaming = () => {
  isRenaming.value = true;
  setTimeout(() => {
    // nextTick(() => (nameInput.value as HTMLInputElement).focus());
    (nameInput.value as HTMLInputElement).focus();
  }, 100);
};
const changeRenamingButtonFocus = (focus: boolean) =>
  (isRenamingButtonFocus.value = focus);

const endRenaming = async () => {
  isRenaming.value = false;
  if (endpointName.value.length !== 0) {
    emit('renameEndpoint', { endpoint: store.state.general.accessTokenInfoModal.endpoint, newName: endpointName.value, namespace: store.state.general.accessTokenInfoModal.endpoint.namespace});
    return;
  }
  endpointName.value = store.state.general.accessTokenInfoModal.endpoint.name;
};
const handleKeyDown = async (event: KeyboardEvent) => {
  if (event.code === 'Enter' || event.code == 'NumpadEnter') {
    await endRenaming()
  }
};
const onFocus = () => {
  (nameInput.value as HTMLInputElement).select();
};
const close = () => {
  emit('close');
};
</script>

<template>
  <ModalTemplate @close="close">
    <div class="flex flex-col gap-12 max-w-[336px]">
      <div class="flex flex-col gap-4">
        <div class="flex gap-3 items-center py-3 border-t border-b border-border-hover">
          <div class="rounded-full p-2 bg-icon-gradient">
            <GetBlockUIIcon class="icon-gradient" icon-name="code-02" color-type="violet" size-type="small" />
          </div>
          <div class="flex flex-row items-center">
            <Transition name="slide-fade">
              <input
                v-if="isRenaming"
                ref="nameInput"
                class="bg-transparent w-full max-w-[160px] truncate text-sm leading-5 font-bold gb-text-text-main shrink-1 transition-all"
                maxlength="20"
                :readonly="!isRenaming"
                :value="endpointName"
                @blur="onBlur"
                @change="endRenaming"
                @focus="onFocus"
                @focusout="onBlur"
                @input="changeEndpointName"
                @keydown="handleKeyDown"
              />
              <p
                v-else
                class="text-sm leading-5 font-bold gb-text-text-main truncate shrink-1"
              >
                {{ endpointName }}
              </p>
            </Transition>
            <p
              class="text-sm leading-5 font-medium gb-text-text-tertiary uppercase whitespace-nowrap ml-2"
            >{{
                store.state.general.accessTokenInfoModal.endpoint.addon.text ||
                store.state.general.accessTokenInfoModal.endpoint.api.text ||
                store.state.general.accessTokenInfoModal.endpoint.addon.value ||
                store.state.general.accessTokenInfoModal.endpoint.api.value
              }}</p>
          </div>
          <GetBlockUIIcon
            v-if="!isRenaming"
            class="shrink-0 cursor-pointer"
            icon-name="edit-01"
            size-type="small"
            :color-type="isRenamingButtonFocus ? 'tertiary' : 'disable'"
            @click="startRenaming"
            @mouseenter="changeRenamingButtonFocus(true)"
            @mouseleave="changeRenamingButtonFocus(false)"
          />
          <GetBlockUIButton
            v-else
            class="shrink-0"
            color-type="accent"
            size-type="small"
            :icon-button="true"
            @click="endRenaming"
          >
            <GetBlockUIIcon color-type="color-bg" icon-name="check" />
          </GetBlockUIButton>
        </div>
        <div class="flex flex-col gap-2">
          <p class="text-xs leading-[14px] font-bold uppercase tracking-[0.48px] gb-text-text-secondary">{{ $t('access-token-info-modal.label') }}</p>
          <div
            class="flex flex-row gap-2 items-center md:grow md:order-2 sm:grow sm:order-2 xs:grow xs:order-2"
          >
            <p
              class="md:w-auto sm:w-auto flex-1 truncate text-sm leading-5 font-medium gb-text-text-main px-4 py-3 gb-bg-surface-additional rounded-12"
            >
              {{ computedUrl }}
            </p>
            <GetBlockUIButton
              color-type="secondary"
              size-type="medium"
              :icon-button="true"
              @click="handleCopyButtonClick(computedUrl)"
            >
              <GetBlockUIIcon v-if="!isCopied" color-type="secondary" icon-name="copy-03" />
              <GetBlockUIIcon v-else color-type="success" icon-name="check-circle" />
            </GetBlockUIButton>
          </div>
        </div>
        <div class="grid grid-rows-3 p-4 border-2 rounded-16 border-border-base gap-3">
          <div v-for="field in FieldsProtocolInfo" :key="field" class="flex justify-between text-sm font-medium">
            <p class="gb-text-text-secondary">{{ $t(`access-token-info-modal.protocol-info.${field}`) }}:</p>
            <p>{{ ProtocolInfo[field] }}</p>
          </div>
        </div>
      </div>
      <div class="flex gap-2 w-full">
        <a class="w-full" target="_blank" href="https://getblock.io/docs/">
          <GetBlockUIButton
            color-type="tint"
            size-type="medium"
            class="w-full"
          >
            <span class="m-auto">{{ $t(`access-token-info-modal.buttons.docs`) }}</span>
          </GetBlockUIButton>
        </a>
        <GetBlockUIButton
          color-type="accent"
          size-type="medium"
          class="w-full"
          @click="close"
        >
          <span class="m-auto">{{ $t(`access-token-info-modal.buttons.continue`) }}</span>
        </GetBlockUIButton>
      </div>
    </div>
  </ModalTemplate>
</template>

<style lang="scss" scoped>
.bg-icon-gradient {
  background: linear-gradient(to top right, rgba(188, 38, 255, 0.2), rgba(98, 38, 255, 0.2));
}
.slide-fade-enter-active {
  transition: all 0.3s ease-in;
}

.slide-fade-leave-active {
  transition: all 0.3s ease-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  opacity: 0;
  width: 0;
  visibility: hidden;
}
</style>
