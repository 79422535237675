import axios from 'axios';
import store from '../store';
import axiosRetry from 'axios-retry';

const AXIOS_CONFIG = {
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
};

const AXIOS_CONFIG_V6 = {
  baseURL: process.env.VUE_APP_API_URL_V6,
  headers: {
    'Content-Type': 'application/json',
  },
};

const AXIOS_CONFIG_APP_CHAIN = {
  baseURL: 'https://0b799xcs3e.execute-api.eu-central-1.amazonaws.com',
  headers: {
    'Content-Type': 'application/json',
  },
}

const httpClient = axios.create(AXIOS_CONFIG);
export const httpClientV6 = axios.create(AXIOS_CONFIG_V6);
const httpClientAppChain = axios.create(AXIOS_CONFIG_APP_CHAIN);

axiosRetry(httpClient, { retries: 3 });

httpClient.interceptors.response.use(
  response => response,
  error => {
    const config: { method: string; url: string } = error.config;
    console.error(`[${config.method.toUpperCase()}] ${config.url} error: ${error}`);
    return Promise.reject(error);
  },
);

axiosRetry(httpClientV6, { retries: 3 });

httpClientV6.interceptors.response.use(
  response => response,
  error => {
    const config: { method: string; url: string } = error.config;
    console.error(`[${config.method.toUpperCase()}] ${config.url} error: ${error}`);
    return Promise.reject(error);
  },
);


const getAllNodes = () => httpClient.get('access_nodes/nodes_v2');

const sendFeedback = (email: string, name: string, message: string, recaptchaToken: string, source: string = 'account.getblock.io') => {
  return httpClient.post(
    '/contacts/',
    {
      email,
      name,
      message: message + `\n\nUID: ${store.state.user.id}`,
      source,
    },
    {
      'axios-retry': {
        retries: 0,
      },
      headers: {
        'recaptcha-token': recaptchaToken,
      },
    },
  );
};
const sendAppChainForm = (email: string, name: string, message: string, recaptchaToken: string, source: string = 'account.getblock.io') => {
  return httpClientAppChain.post(
    '/send_form',
    {
      email,
      name,
      message: message + `\n\nUID: ${store.state.user.id}`,
      source,
    },
    {
      'axios-retry': {
        retries: 0,
      },
      headers: {
        'recaptcha-token': recaptchaToken,
      },
    },
  )
}


const generateGift = (language: string = 'en') =>
  httpClient.post('/gifts/', { language }, { headers: { Authorization: `Bearer ${store.state.accessToken}` } });

const getGift = () =>
  httpClient.get('/gifts/', {
    headers: {
      Authorization: `Bearer ${store.state.accessToken}`,
    },
  });

export { getAllNodes, sendFeedback, generateGift, getGift, sendAppChainForm };
