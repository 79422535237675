export const onboardingData = {
  'base': [
    {
      page: '/',
      elementsToHighlight: ['protocol-dropdown', 'network-dropdown', 'api-dropdown', 'get-access-token-button'],
    },
    {
      page: '/',
      elementsToHighlight: ['download-configuration-button'],
    },
    {
      page: '/',
      elementsToHighlight: ['dashboard-tabbar'],
    },
    {
      page: '/profile/my-balance',
      elementsToHighlight: ['requests-card', 'unlimited-card', 'dedicated-card'],
    },
    {
      page: '/dedicated-nodes',
      elementsToHighlight: ['protocol-dropdown', 'network-dropdown', 'go-to-configurator-button', 'go-to-dedicated-nodes-button'],
    },
    {
      page: '/dedicated-nodes',
      elementsToHighlight: [],
    },
  ],
};
