const s3BaseUrl: string = 'https://storage.getblock.io/web/account/avatars/';

const countOfAvatars: number = 12;
const avatarImages: string[] = Array.from({ length: countOfAvatars }, (_, index) => `avatar${index + 1}.svg`);
const hashString = (str: string): number => {
  let hash: number = 0;
  for (let i: number = 0; i < str.length; i++) {
    const char: number = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0;
  }
  return Math.abs(hash);
};

const setAvatar = (teamId: string): string => {
  const hash: number = hashString(teamId);
  const index: number = hash % avatarImages.length;
  return `${s3BaseUrl}${avatarImages[index]}`;
};

export { setAvatar };
