import { i18n } from '@/main';


export function useNotification() {
  // note: function that return you a name of your data in form namespace_nameOfTrigger, you should pass data here in suitable format
  type DataResultType<T> = {
    [K in keyof T]:
    `${Exclude<K, symbol>}_${Exclude<keyof T[K], symbol>}`
  }
  type ResultType<T> = DataResultType<T>[keyof T];

  const { t } = i18n.global;

  const errors = {
    authentication: {
      register: {
        title: t('notification.error.authentication.register'),
        description: t('notification.error.error-description'),
      },
      login: {
        title: t('notification.error.authentication.login'),
        description: t('notification.error.error-description'),
      },
      'sign-in': {
        title: t('notification.error.authentication.sign-in'),
        description: '',
      },
      'recover-password': {
        title: t('notification.error.authentication.recover-password'),
        description: t('notification.error.error-description'),
      },
      'set-password': {
        title: t('notification.error.authentication.set-password'),
        description: t('notification.error.error-description'),
      },
      'reset-password': {
        title: t('notification.error.authentication.reset-password'),
        description: t('notification.error.error-description'),
      },
    },
    webhooks: {
      'get-public-key': {
        title: t('notification.error.webhooks.get-public-key'),
        description: t('notification.error.error-description'),
      },
      'generate-public-key': {
        title: t('notification.error.webhooks.generate-public-key'),
        description: t('notification.error.error-description'),
      },
    },
    general: {
      'get-pricing': {
        title: t('notification.error.general.get-pricing'),
        description: t('notification.error.error-description'),
      },
      'get-increase-pricing': {
        title: t('notification.error.general.get-increase-pricing'),
        description: t('notification.error.error-description'),
      },
      'get-list-of-nodes': {
        title: t('notification.error.general.get-list-of-nodes'),
        description: t('notification.error.error-description'),
      },
      'create-order': {
        title: t('notification.error.general.create-order'),
        description: t('notification.error.error-description'),
      },
      'get-statistics': {
        title: t('notification.error.general.get-statistics'),
        description: t('notification.error.error-description'),
      },
      'send-message': {
        title: t('notification.error.general.send-message'),
        description: t('notification.error.error-description'),
      },
    },
    'dedicated-order': {
      'calculate-price': {
        title: '',
        description: t('notification.error.dedicated-order.calculate-price'),
      },
      'get-dedicated-nodes': {
        title: '',
        description: t('notification.error.dedicated-order.get-dedicated-nodes'),
      },
      'create-order': {
        title: '',
        description: t('notification.error.dedicated-order.create-order'),
      },
      'error-description': {
        title: '',
        description: t('notification.error.error-description'),
      },
    },
    projects: {
      'get-list-of-projects': {
        title: t('notification.error.projects.get-list-of-projects'),
        description: t('notification.error.error-description'),
      },
      'hide-endpoint': {
        title: t('notification.error.projects.hide-endpoint'),
        description: t('notification.error.error-description'),
      },
      'get-new-access-token': {
        title: t('notification.error.projects.get-new-access-token'),
        description: t('notification.error.error-description'),
      },
      'get-statistics': {
        title: t('notification.error.projects.get-statistics'),
        description: t('notification.error.error-description')
      }
    },
    endpoints: {
      'get-endpoints': {
        title: t('notification.error.endpoints.get-endpoints'),
        description: t('notification.error.error-description'),
      },
      'create-endpoint': {
        title: t('notification.error.endpoints.create-endpoint'),
        description: t('notification.error.error-description'),
      },
      'delete-endpoint': {
        title: t('notification.error.endpoints.delete-endpoint'),
        description: t('notification.error.error-description'),
      },
      'roll-endpoint': {
        title: t('notification.error.endpoints.roll-endpoint'),
        description: t('notification.error.error-description'),
      },
      'rename-endpoint': {
        title: t('notification.error.endpoints.rename-endpoint'),
        description: t('notification.error.error-description'),
      }
    },
    user: {
      'get-checkout-history': {
        title: t('notification.error.user.get-payment-history'),
        description: t('notification.error.error-description'),
      },
      'get-payment-history': {
        title: t('notification.error.user.get-payment-history'),
        description: t('notification.error.error-description'),
      },
      'get-account-information': {
        title: t('notification.error.user.get-account-information'),
        description: t('notification.error.error-description'),
      },
      'get-requests-balance': {
        title: t('notification.error.user.get-requests-balance'),
        description: t('notification.error.error-description'),
      },
      'get-referral-info': {
        title: t('notification.error.user.get-referral-info'),
        description: t('notification.error.error-description'),
      },
      'change-account-information': {
        title: t('notification.error.user.change-account-information'),
        description: t('notification.error.error-description'),
      },
      'accept-referral-program': {
        title: t('notification.error.user.accept-referral-program'),
        description: t('notification.error.error-description'),
      },
      'send-payout-code': {
        title: t('notification.error.user.send-payout-code'),
        description: t('notification.error.error-description'),
      },
      'create-payout': {
        title: t('notification.error.user.create-payout'),
        description: t('notification.error.error-description'),
      },
      'get-notifications': {
        title: t('notification.error.user.get-notifications'),
        description: t('notification.error.error-description'),
      },
      'change-notifications-trigger': {
        title: t('notification.error.user.change-trigger-destination'),
        description: t('notification.error.error-description'),
      },
      'enable-notifications-email-destination': {
        title: t('notification.error.user.enable-notifications-email-destination'),
        description: t('notification.error.error-description'),
      },
      'enable-notifications-telegram-destination': {
        title: t('notification.error.user.enable-notifications-telegram-destination'),
        description: t('notification.error.error-description'),
      },
      'enable-notifications-webhook-destination': {
        title: t('notification.error.user.enable-notifications-webhook-destination'),
        description: t('notification.error.error-description'),
      },
      'disable-notifications-email-destination': {
        title: t('notification.error.user.disable-notifications-email-destination'),
        description: t('notification.error.error-description'),
      },
      'disable-notifications-telegram-destination': {
        title: t('notification.error.user.disable-notifications-telegram-destination'),
        description: t('notification.error.error-description'),
      },
      'disable-notifications-webhook-destination': {
        title: t('notification.error.user.disable-notifications-webhook-destination'),
        description: t('notification.error.error-description'),
      },
      'change-notifications-webhook-url': {
        title: t('notification.error.user.change-notifications-webhook-url.title'),
        description: t('notification.error.user.change-notifications-webhook-url.description'),
      },
    },
    payment: {
      payment: {
        title: t('notification.error.payment.payment.title'),
        description: t('notification.error.payment.payment.description'),
      },
      'forbidden-country': {
        title: t('notification.error.payment.forbidden-country.title'),
        description: '',
      },
    },
    teams: {
      'create-new-team': {
        title: t('notification.error.teams.create-new-team'),
        description: t('notification.error.error-description'),
      },
      'get-team-list': {
        title: t('notification.error.teams.get-team-list'),
        description: t('notification.error.error-description'),
      },
      'get-current-team': {
        title: t('notification.error.teams.get-current-team'),
        description: t('notification.error.error-description'),
      },
      'get-invite-list': {
        title: t('notification.error.teams.get-invite-list'),
        description: t('notification.error.error-description'),
      },
      'respond-invite': {
        title: t('notification.error.teams.respond-invite'),
        description: t('notification.error.error-description'),
      },
      'rename-team': {
        title: t('notification.error.teams.rename-team'),
        description: t('notification.error.error-description'),
      },
      'remove-member': {
        title: t('notification.error.teams.remove-member'),
        description: t('notification.error.error-description'),
      },
      'add-member': {
        title: t('notification.error.teams.add-member'),
        description: t('notification.error.error-description'),
      },
      'rename-member': {
        title: t('notification.error.teams.rename-member'),
        description: t('notification.error.error-description'),
      },
      'team-auth': {
        title: t('notification.error.teams.team-auth'),
        description: t('notification.error.error-description'),
      },
      'set-access-level': {
        title: t('notification.error.teams.set-access-level'),
        description: t('notification.error.error-description'),
      },
      'info': {
        title: t('notification.error.teams.info'),
        description: t('notification.error.error-description'),
      }
    },
  };

  const success = {
    authentication: {
      register: {
        title: t('notification.success.authentication.register.title'),
        description: t('notification.success.authentication.register.description'),
      },
      'forgot-password': {
        title: t('notification.success.authentication.forgot-password.title'),
        description: t('notification.success.authentication.forgot-password.description'),
      },
    },
    general: {
      'send-feedback': {
        title: t('notification.success.general.send-feedback.title'),
        description: t('notification.success.general.send-feedback.description'),
      },
    },
    'dedicated-order': {
      'create-project': {
        title: t('notification.success.dedicated-order.create-project.title'),
        description: t('notification.success.dedicated-order.create-project.description'),
      },
    },
    payment: {
      completed: {
        title: t('notification.success.payment.completed.title'),
        description: t('notification.success.payment.completed.description'),
      },
    },
    projects: {
      'hide-endpoint': {
        title: t('notification.success.project.hide-endpoint.title'),
      },
      'copy-endpoint': {
        title: t('notification.success.project.copy-endpoint.title')
      },
      'copy-token': {
        title: t('notification.success.project.copy-token.title')
      }
    },
  };

  const attention = {
    projects: {
      'delete-dedicated-project': {
        title: t('notification.attention.projects.delete-dedicated-project.title'),
        description: t('notification.attention.projects.delete-dedicated-project.description'),
      },
      'delete-dedicated-endpoint': {
        title: t('notification.attention.projects.delete-dedicated-endpoint.title'),
        description: t('notification.attention.projects.delete-dedicated-endpoint.description'),
      },
    },
    webhooks: {
      'service-unavailable': {
        title: '',
        description: 'The service is currently unavailable due to technical maintenance. GetBlock Tracker will be back online shortly.',
      },
    },
    authentication: {
      metamask: {
        title: t('notification.attention.authentication.metamask.title'),
        description: t('notification.attention.authentication.metamask.description'),
      },
    },
    teams: {
      'no-access-uid': {
        title: t('notification.attention.teams.no-access-uid.title'),
        description: t('notification.attention.teams.no-access-uid.description'),
      },
    },
  };

  type ErrorNameType = ResultType<typeof errors>;

  const errorData = (errorName: ErrorNameType) => {
    const namespace = errorName.split('_')[0] as keyof typeof errors;
    const type = errorName.split('_')[1];
    return {
      // TODO: think how to not use ts-ignore here
      // @ts-ignore
      title: errors[namespace][type].title,
      // @ts-ignore
      description: errors[namespace][type].description,
    };
  };

  type SuccessNameType = ResultType<typeof success>;

  const successData = (successName: SuccessNameType) => {
    const namespace = successName.split('_')[0] as keyof typeof success;
    const type = successName.split('_')[1];
    // TODO: check how to lint it correctly with ts-ignore
    return {
      // @ts-ignore
      title: success[namespace][type].title,
      // @ts-ignore
      description: success[namespace][type].description,
    };
  };

  type AttentionNameType = ResultType<typeof attention>;

  const attentionData = (successName: AttentionNameType) => {
    const namespace = successName.split('_')[0] as keyof typeof attention;
    const type = successName.split('_')[1];
    // TODO: check how to lint it correctly with ts-ignore
    return {
      // @ts-ignore
      title: attention[namespace][type].title,
      // @ts-ignore
      description: attention[namespace][type].description,
    };
  };

  return { errorData, successData, attentionData };
}


