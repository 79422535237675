import { getAllNodes } from '@/api/index';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
// @ts-ignore
import { GeneralActions, GeneralGetters, GeneralMutations, GeneralState } from '@/types/shared/store';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '@/types/store';
import { Node } from '@/types/shared/shared';
import { useNotification } from '@/composables/useNotification';
import { MappedEndpoint, Namespace } from '@/types/dashboard/sharedNodes/endpoint';
import { sendContactFormAnalytics } from '@/utils/contactFormAnalytics';

export type State = GeneralState;
export type Mutations = GeneralMutations;
export type Getters = GeneralGetters;
export type Actions = GeneralActions;


const state: () => State = () => ({
  contactModal: {
    display: false,
    contacts: 'other'
  },
  accessTokenInfoModal: {
    display: false,
    endpoint: {
      name: '',
      _id: '',
      _key: '',
      namespace: 'shared',
      protocol: '',
      dn_id: '',
      update_at: '',
      statisticsPreview: [],
      network: {
        value: '',
        text: '',
      },
      api: {
        value: '',
        text: '',
      },
      addon: {
        value: '',
        text: '',
      },
    }
  },
  contactModalPaymentManager: {
    display: false,
    type: 'unlim'
  },
  notificationMessage: {
    title: '',
    description: '',
    type: 'error',
  },
  showNotificationMessage: false,
  nodes: [],
  loading: false,
  accountType: '',
  contactModalData: {
    subject: 'customer-support',
    'additional-tag': '',
  },
  onboardingData: {
    type: 'base',
    stepNumber: 0,
  },
  showOnboarding: {
    stepsModal: false,
  },
  showCodeSnippetModal: false,
  isNeededToGoToEndpointsDashboardTab: false,
  showAccountDropdownMenu: false,
});

const getters: GetterTree<State, RootState> & Getters = {
  availableSharedNodes(state) {
    return state.nodes.filter((node: Node) => node.service_levels.find((serviceLevel) => serviceLevel._key === 'shared'));
  },
  availableDedicatedNodes(state) {
    return state.nodes.filter((node: Node) => node.service_levels.find((serviceLevel) => serviceLevel._key === 'dedicated'));
  },
  nodes(state) {
    return state.nodes;
  },
};

const mutations: MutationTree<State> & Mutations = {
  setAccessTokenInfo(state, endpoint) {
    state.accessTokenInfoModal.endpoint = endpoint
  },
  setAccessTokenInfoModalDisplay(state, display) {
    state.accessTokenInfoModal.display = display
  },
  setAllNodes(state, nodes) {
    state.nodes = nodes;
  },
  setContactModalPaymentManager(state, modalInfo) {
    state.contactModalPaymentManager = modalInfo
  },
  setNotificationMessage(state, data) {
    state.notificationMessage = data;
  },
  setContactModalDisplay(state, data ) {
    state.contactModal = data;
    changeScrollState('contactModal', data.display);
    if (data.contacts === "sales") sendContactFormAnalytics();
  },
  setNotificationMessageDisplay(state, display) {
    state.showNotificationMessage = display;
  },
  setInitialLoading(state, loading) {
    state.loading = loading;
  },
  setContactModalData(state, contactModalData) {
    state.contactModalData = { ...state.contactModalData, ...contactModalData };
  },
  setOnboardingData(state, onboardingData) {
    state.onboardingData = { ...state.onboardingData, ...onboardingData };
  },
  setShowOnboarding(state, showOnboarding) {
    state.showOnboarding = {
      ...state.showOnboarding,
      ...showOnboarding,
    };
  },
  setShowCodeSnippetModal(state, show) {
    state.showCodeSnippetModal = show;
  },
  setIsNeededToGoToEndpointsDashboardTab(state, isNeededToGoToEndpointsDashboardTab) {
    state.isNeededToGoToEndpointsDashboardTab = isNeededToGoToEndpointsDashboardTab;
  },
  setShowAccountDropdownMenu(state, show) {
    state.showAccountDropdownMenu = show;
  },
};

const actions: ActionTree<State, RootState> & Actions = {
  async setAllNodes({ commit }) {
    try {
      const res = await getAllNodes();
      commit('setAllNodes', res.data);
    } catch (error) {
      const { errorData } = useNotification();
      commit('setNotificationMessage', { ...errorData('general_get-list-of-nodes'), type: 'error' });
      commit('setNotificationMessageDisplay', true);
    }
  },
};

const general: Module<State, RootState> = {
  state,
  mutations,
  getters,
  actions,
};

export default general;

// TODO: move it to utils
const changeScrollState = (elementId: string, show: boolean) => {
  const el = document.getElementById(elementId);
  if (show) disableBodyScroll(el as Element);
  else enableBodyScroll(el as Element);
};
