import { IDedicatedNode } from '@/entities/dedicatedNode/types';
import { IAccessTokenDedicated } from '@/entities/accessToken/types';

export function useMergingAccessTokensWithDedicatedNode(dedicatedNode: IDedicatedNode, accessTokens: IAccessTokenDedicated[]) {
  const dedicatedNodeId = dedicatedNode._id
  const dedicatedNodeAccessTokens  = accessTokens.filter((accessToken) => {
    return accessToken.dn_id === dedicatedNodeId
  })
  if (dedicatedNodeAccessTokens.length === 0) dedicatedNode.access_tokens = {}
  else dedicatedNodeAccessTokens.forEach((accessToken) => {
    if (!dedicatedNode.access_tokens) dedicatedNode.access_tokens = {}
    else dedicatedNode.access_tokens[accessToken._id] = accessToken
  })
  return dedicatedNode
}