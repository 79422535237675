import { httpClientV3 } from '@/shared/api/httpClient';
import store from '@/store';
import { AxiosResponse } from 'axios';
import { IUserRPSInfo } from '@/entities/rps/types';

const fetchRPS = () => httpClientV3.get('/user/rps/', {
  headers: {
    Authorization: `Bearer ${store.getters.isPersonalAccount ? store.state.accessToken : store.state.teams.teamToken}`,
  },
}) as Promise<AxiosResponse<IUserRPSInfo>>

export {
  fetchRPS
}