import axios from 'axios';

const AXIOS_CONFIG_V6 = {
  baseURL: process.env.VUE_APP_API_URL_V6,
  headers: {
    'Content-Type': 'application/json',
  },
};

const AXIOS_CONFIG_V3 = {
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
};

export const httpClientV6 = axios.create(AXIOS_CONFIG_V6);
export const httpClientV3 = axios.create(AXIOS_CONFIG_V3);